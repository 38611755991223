/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }

      me: file(relativePath: { eq: "me_cartoon.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 50, height: 50, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <Img
        fixed={data.me.childImageSharp.fixed}
        alt="Tobias Brandenberg"
        className="w-16 border-4 rounded-full mx-auto border-white"
      />

      {author?.name && (
        <div>
          Veröffentlicht von <strong>{author.name}</strong>{" "}
          <div className="text-xs">{author?.summary || null}</div>
          {` `}
          <a href={`https://twitter.com/${social?.twitter || ``}`}>
            Auf twitter folgen
          </a>
        </div>
      )}
    </div>
  )
}

export default Bio
